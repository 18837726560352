import { IAuthResponse } from '@/auth/models/IAuth'
import { PATHS } from '@/auth/queries'
import axios from 'axios'
import { retrieveLaunchParams } from '@telegram-apps/sdk'

import { checkTokenIsExpired } from './token'

export const baseURL = import.meta.env.VITE_ENABLE_MOCK === 'true' ? '/' : import.meta.env.VITE_API_URL || '/'

const axiosInstance = axios.create({
  baseURL,
})

const getNewToken = async () => {
  const launchParams = retrieveLaunchParams()
  const response = await axios.post<IAuthResponse>(baseURL + PATHS.auth, {
    initData: launchParams.initDataRaw,
  })
  if (response.data.access_token) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access_token}`
  } else {
    axiosInstance.defaults.headers.Authorization = ''
  }
}

axiosInstance.interceptors.request.use(async (req) => {
  if (axiosInstance.defaults.headers.Authorization) {
    const token = axiosInstance.defaults.headers.Authorization?.toString()?.split?.(' ')?.[1]
    if (checkTokenIsExpired(token)) {
      await getNewToken()
    }
  }

  return req
})

axiosInstance.interceptors.response.use(null, async (err) => {
  if (err?.response?.status === 401) {
    await getNewToken()
  }

  throw err
})

export default axiosInstance
