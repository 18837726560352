import cx from 'clsx'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { Button } from 'flowbite-react/components/Button'
import Skeleton from '@/components/Skeleton'
import Datepicker from '@/components/Datepicker'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import Status from '@/components/Status'
import Priority from '@/components/Priority'
import Attachments from '../Attachments'

import UserSelect from '@/projects/components/UserSelect'
import { useDeleteTicket, useGetTicket, usePatchTicket } from '@/projects/queries'
import { useToggleOpen } from '@/utils/hooks/useToggle'
import { TrashIcon } from '@heroicons/react/24/outline'
import routes from '@/utils/routes'
import { debounce } from '@/utils'
import { sendEvent } from '@/utils/hooks/useAmplitude'

export interface TicketContentProps {}

type Inputs = {
  description: string
  title: string
}

const MAX_DESCRIPTION_LENGTH = 5000

export const TicketContent: React.FC<TicketContentProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket' })
  const { ticketId, projectId, teamspaceId } = useParams<{ ticketId: string; projectId: string; teamspaceId: string }>()

  const { data: ticket } = useGetTicket(ticketId, teamspaceId, projectId)

  const navigate = useNavigate()
  const { register, getFieldState, watch, reset, setFocus } = useForm<Inputs>({
    values: {
      description: ticket?.description || '',
      title: ticket?.title || '',
    },
  })
  const descriptionRef = useRef<HTMLTextAreaElement>()
  const numberRef = useRef<HTMLDivElement>(null)

  const { isOpen: isDeleteOpen, toggleOpen: toggleDelete } = useToggleOpen()

  const { mutateAsync: patchTicket } = usePatchTicket(ticket?.teamspace_id, ticket?.project_id, ticket?.uuid)
  const { mutateAsync: deleteTicket, isPending } = useDeleteTicket(
    ticket?.teamspace_id,
    ticket?.project_id,
    ticket?.uuid,
  )

  const description = watch('description')
  const descriptionField = register('description')
  const descriptionState = getFieldState('description')
  const handleInputDescription = () => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = ''
      descriptionRef.current.style.height = descriptionRef.current.scrollHeight + 40 + 'px'
    }
  }

  useEffect(() => {
    handleInputDescription()
  }, [])

  const handleDeleteTicket = async () => {
    await deleteTicket()
    sendEvent('delete-ticket')
    navigate(generatePath(routes.private.board, { teamspaceId: ticket?.teamspace_id, projectId: ticket?.project_id }))
  }

  const saveTitle = (title: string) => {
    patchTicket({ title })
  }

  const saveTitleDebounced = debounce(saveTitle, 1000)

  useLayoutEffect(() => {
    const ticketTitleElement = document.getElementById('ticket-title')
    if (ticket && numberRef.current && ticketTitleElement) {
      ticketTitleElement.style.paddingLeft = `${numberRef.current?.scrollWidth + 4}px`
    }
  }, [ticket])

  const titleProps = register('title')

  const onChangeTitle: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    titleProps.onChange(event)
    saveTitleDebounced(event.target.value)
  }

  return (
    <div className="flex h-full flex-col gap-3">
      <div className="flex items-center justify-between gap-2">
        <Skeleton isActive={!!ticket}>
          <div className={cx('relative w-full text-neutral-600 dark:text-neutral-200')}>
            <div
              className="absolute left-0 px-0.5"
              ref={numberRef}
              onClick={() => {
                setFocus('title')
              }}
            >
              {ticket?.ticket_id}:
            </div>
            <input
              className="w-full bg-transparent"
              id="ticket-title"
              {...titleProps}
              onChange={onChangeTitle}
              maxLength={70}
              data-amp-mask
            />
          </div>
        </Skeleton>
        <div className="flex gap-2">
          <Skeleton isActive={!!ticket} className="w-7">
            <Priority priority={ticket?.priority} onChange={(priority) => patchTicket({ priority })} />
          </Skeleton>
          <UserSelect
            onSelect={(assignee) => {
              patchTicket({ assignee })
            }}
            value={ticket?.assignee}
          />
        </div>
      </div>

      <div className="flex gap-3">
        <div className="flex flex-col gap-1">
          <span className="text-xs">{t('statusLabel')}</span>
          <Skeleton isActive={!!ticket} className="w-24">
            <Status status={ticket?.status} onChange={(status) => patchTicket({ status })} />
          </Skeleton>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xs">{t('startDateLabel')}</span>
          <Skeleton isActive={!!ticket}>
            <Datepicker
              onChange={(value) => {
                patchTicket({ start_date: dayjs(value).format('YYYY-MM-DD') })
              }}
              placeholder={t('notSetPlaceholder')}
              value={ticket?.start_date ? new Date(ticket?.start_date) : undefined}
              isClearable
            />
          </Skeleton>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xs">{t('dueDateLabel')}</span>
          <Skeleton isActive={!!ticket}>
            <Datepicker
              onChange={(value) => {
                patchTicket({ due_date: dayjs(value).format('YYYY-MM-DD') })
              }}
              placeholder={t('notSetPlaceholder')}
              value={ticket?.due_date ? new Date(ticket?.due_date) : undefined}
              isClearable
            />
          </Skeleton>
        </div>
      </div>
      <Skeleton isActive={!!ticket} className="h-36 w-full">
        <div className="relative w-full">
          <textarea
            className="transition-color max-h-[300px] min-h-36 w-full overflow-y-hidden rounded border-none bg-wall-secondary-bg-light p-2 pb-10 ring-2 ring-transparent hover:ring-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:bg-wall-secondary-bg-dark"
            {...descriptionField}
            maxLength={MAX_DESCRIPTION_LENGTH}
            ref={(ref) => {
              descriptionField.ref(ref)
              descriptionRef.current = ref || undefined
            }}
            data-amp-mask
            onInput={handleInputDescription}
          />
          {descriptionState.isDirty && (
            <div className="absolute bottom-3 right-2 flex justify-end gap-2">
              <Button
                size="xs"
                color="red"
                onClick={() => {
                  reset()
                }}
              >
                {t('cancelLabel')}
              </Button>
              <Button
                size="xs"
                color="primary"
                onClick={() => {
                  patchTicket({ description })
                  reset({ description })
                }}
              >
                {t('saveLabel')}
              </Button>
            </div>
          )}
        </div>
      </Skeleton>

      <div className="flex grow flex-col justify-between gap-2">
        <Skeleton isActive={!!ticket} className="h-14 w-full">
          <Attachments />
        </Skeleton>
        <div className={cx('flex items-end justify-end')}>
          <button onClick={toggleDelete} className="text-red-500 hover:text-red-700">
            <TrashIcon className="h-6 w-6" />
          </button>
          <ConfirmationDialog
            isOpen={isDeleteOpen}
            onClose={toggleDelete}
            onConfirm={handleDeleteTicket}
            confirmationTitle={t('deleteTitle', { number: ticket?.ticket_id })}
            isLoading={isPending}
          />
        </div>
      </div>
    </div>
  )
}

export default TicketContent
