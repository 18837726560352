import { useEffect } from 'react'
import { useHapticFeedback } from '@telegram-apps/sdk-react'
import { useForm, useWatch } from 'react-hook-form'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'

import TitleInput from './components/TitleInput'
import DescriptionInput from './components/DescriptionInput'
import ProjectSelector from './components/ProjectSelector'
import Priority from '@/components/Priority'
import useSaveButton from './components/SaveButton'
import Datepicker from '@/components/Datepicker'
import Status from '@/components/Status'
import Skeleton from '@/components/Skeleton'
import UserSelect from '@/projects/components/UserSelect'
import Attachments from '../Ticket/components/Attachments'

import useBackButton from '@/utils/hooks/useBackButton'
import { uploadFile, useCreateTicket, useGetProject } from '@/projects/queries'
import { TicketType, Priority as PriorityEnum } from '@/projects/models/IProject'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import { priorities } from '@/constants/fields'
import { withLoader } from '@/utils/hocs/withLoader'
import { project as projectAtom } from '@/projects/store'

const formatDate = (date: Date | null) => (date ? dayjs(date).format('YYYY-MM-DD') : null)

export interface NewTicketProps {
  id?: string
}

type Inputs = {
  title: string
  description: string
  project: string
  status: string
  priority: PriorityEnum
  startDate: Date | null
  dueDate: Date | null
  assignee: string | null
  inviteUuid: string
  attachments?: File[]
}

const NewTicket: React.FC<NewTicketProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket' })
  const haptic = useHapticFeedback()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [storeProjectId] = useAtom(projectAtom)

  const { teamspaceId } = useParams<{ teamspaceId: string }>()

  const projectId = state?.projectId || storeProjectId

  const { mutateAsync: create } = useCreateTicket(teamspaceId, projectId)
  const { backRoute } = useBackButton(teamspaceId, projectId)

  const { register, setValue, watch, getValues, control, resetField, unregister, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      project: projectId || undefined,
      priority: priorities[1].id,
    },
    shouldUnregister: true,
  })
  register('status')

  const { data: currentProject } = useGetProject(projectId, teamspaceId)

  const status = useWatch<Inputs, 'status'>({ name: 'status', control })
  const project = useWatch<Inputs, 'project'>({ name: 'project', control })

  useEffect(() => {
    if (currentProject) {
      setValue('status', currentProject.settings.statuses[0].uuid)
    }
  }, [currentProject])

  useEffect(() => {
    return () => {
      unregister()
    }
  }, [])

  const handleSave = async () => {
    haptic.notificationOccurred('success')
    setLoading(true)

    const { description, priority, status, title, startDate, dueDate, assignee, attachments, inviteUuid } = getValues()

    const data = await create({
      type: TicketType.Task,
      assignee: assignee || null,
      is_recurring: false,
      start_date: formatDate(startDate),
      due_date: formatDate(dueDate),
      description,
      priority,
      status,
      title,
      invite_uuid: inviteUuid,
    })
    if (attachments?.length) {
      attachments.forEach((file) => {
        uploadFile(file, data.project_id, data.teamspace_id, data.uuid)
      })
    }

    sendEvent('create-ticket')
    setLoading(false)

    navigate(backRoute)
  }

  const { setLoading } = useSaveButton({ onSave: handleSubmit(handleSave) })

  const getOnChange = (id: keyof Inputs) => (value: string | Date | null | File[]) => {
    setValue(id, value)
    if (id === 'project') {
      resetField('status')
      resetField('assignee')
    }
  }

  return (
    <div className="h-dinamic overflow-y-auto p-3">
      <form className="rounded-md bg-wall-secondary-bg-light *:border-b *:px-3 *:py-2 last:*:border-none dark:bg-wall-secondary-bg-dark dark:*:border-gray-500">
        <TitleInput {...register('title', { required: true, minLength: 1 })} data-amp-mask />
        <div className="flex items-center justify-between gap-1">
          <div className="flex w-full justify-between">
            <div className="flex gap-1">
              <Status onChange={getOnChange('status')} status={status} projectId={project} />
              <Priority {...register('priority')} onChange={getOnChange('priority')} priority={watch('priority')} />
            </div>
            <UserSelect
              {...register('assignee')}
              onSelect={getOnChange('assignee')}
              value={watch('assignee')}
              projectId={watch('project')}
              setInviteUuid={getOnChange('inviteUuid')}
            />
          </div>
        </div>
        <DescriptionInput {...register('description')} data-amp-mask />
        <div className="flex items-center gap-1">
          <Datepicker
            onChange={getOnChange('startDate')}
            placeholder={t('notSetPlaceholder')}
            value={watch('startDate')}
            label={t('startDateLabel')}
            isClearable
          />
          <Datepicker
            onChange={getOnChange('dueDate')}
            placeholder={t('notSetPlaceholder')}
            value={watch('dueDate')}
            label={t('dueDateLabel')}
            isClearable
          />
        </div>
        <Attachments onChange={getOnChange('attachments')} isNewTicket />
        <div className="flex justify-end">
          <ProjectSelector {...register('project')} onChange={getOnChange('project')} value={watch('project')} />
        </div>
      </form>
    </div>
  )
}

export const Ticket = withLoader(NewTicket, <Skeleton isActive className="h-full w-full" />)
export default Ticket
