import { Button, Checkbox, Drawer } from 'flowbite-react'
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

export type Option<T = string> = {
  value: T
  label?: string
  shortLabel?: string
  color?: string
  element?: JSX.Element
  clickable?: boolean
  [key: string]: unknown
}

export interface SelectProps<T = string, IsMulti extends boolean = false> {
  value?: IsMulti extends true ? T[] : T
  title?: string
  Button?: FC<
    Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'value'> & {
      value?: IsMulti extends true ? T[] : T
    }
  >
  options: Option<T>[]
  disabled?: boolean
  onChange?: (value: IsMulti extends true ? T[] : T) => void
  buttonClassName?: string
  drawerClassName?: string
  onBlur?: () => void
  onFocus?: () => void
  placeholder?: string
  Icon?: React.SVGProps<SVGSVGElement>
  defaultColor?: string
  open?: boolean
  isMulti?: IsMulti
  isClearable?: boolean
}

export function Select<T = string, IsMulti extends boolean = false>({
  value: initialValue,
  title,
  options,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
  buttonClassName,
  drawerClassName,
  Button: ButtonElement,
  Icon,
  placeholder,
  defaultColor,
  open,
  isMulti,
  isClearable,
}: SelectProps<T, IsMulti>) {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(open)
  const isIos = useIsIos()
  const id = useId()

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const selectedValue = useMemo(() => {
    if (Array.isArray(value)) {
      return options.filter((s) => value.includes(s.value))
    }
    return options.find((s) => s.value === value)
  }, [value, options])

  const handleClose = () => {
    setIsOpen(false)
    onBlur?.()
  }

  const handleSelect = (selectedValue?: T) => {
    if (Array.isArray(value) && isMulti) {
      const newValue = value.includes(selectedValue)
        ? value.filter((v) => v !== selectedValue)
        : [...value, selectedValue]
      setValue(newValue as IsMulti extends true ? T[] : T)
      onChange?.(newValue as IsMulti extends true ? T[] : T)
    } else {
      setValue(selectedValue as IsMulti extends true ? T[] : T)
      onChange?.(selectedValue as IsMulti extends true ? T[] : T)
      handleClose()
    }
  }

  useEffect(() => {
    const drawer = document.getElementById(id)
    if (drawer && isOpen) {
      drawer.nextSibling?.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        handleClose()
      })
    }
  }, [isOpen])

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (disabled) return
      onFocus?.()
      setIsOpen(true)
    },
    [disabled],
  )

  return (
    <>
      {ButtonElement ? (
        <ButtonElement onClick={onClick} value={value} />
      ) : (
        <Button
          onClick={onClick}
          size="xs"
          color="gray"
          style={{
            color: Array.isArray(selectedValue)
              ? (selectedValue[0]?.color ?? defaultColor)
              : (selectedValue?.color ?? defaultColor),
          }}
          type="button"
          className={twMerge(
            cx(
              'text-black dark:bg-wall-secondary-bg-dark dark:text-neutral-100 dark:hover:bg-wall-main-bg-dark [&>span]:items-center [&>span]:justify-between [&>span]:gap-1',
              buttonClassName,
              {
                'bg-wall-purple dark:bg-wall-purple-dark': isMulti && !!selectedValue?.length,
              },
            ),
          )}
        >
          <>
            {!!Icon && Icon}
            {isMulti ? placeholder : (selectedValue as Option<T>)?.label || placeholder}
            {isMulti && <ChevronDownIcon className="h-4 w-4" />}
          </>
        </Button>
      )}
      {!disabled && (
        <Drawer
          autoFocus={false}
          open={isOpen}
          onClose={handleClose}
          position="bottom"
          className={cx('rounded-t-md [&_h5]:mb-0 [&_h5]:font-medium', drawerClassName, {
            'pb-10': isIos,
          })}
          id={id}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Drawer.Header title={title} titleIcon={() => null} className="mb-2" />
          <Drawer.Items className="flex max-h-[80vh] flex-col items-start gap-2 overflow-auto rounded p-2">
            {options.map(({ label, value: optionValue, color, element, clickable = true }) => {
              const child = element || <>{label}</>
              const isSelected = Array.isArray(value) ? value.includes(optionValue) : value === optionValue

              return (
                <React.Fragment key={optionValue?.toString()}>
                  {clickable ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleSelect(optionValue)
                      }}
                      style={{ color }}
                      className={cx(
                        'flex w-full items-center justify-between gap-2 rounded px-2 py-1 text-start text-sm transition-colors',
                        {
                          'bg-wall-main-light/10 hover:bg-wall-main-light/20 dark:bg-wall-main-dark/10 dark:hover:bg-wall-main-dark/20':
                            value === optionValue,
                          'bg-wall-main-bg-light hover:bg-wall-main-light/10 dark:bg-wall-main-bg-dark dark:hover:bg-wall-main-dark/10':
                            value !== optionValue,
                        },
                      )}
                    >
                      {child}
                      {(isMulti || !!isSelected) && (
                        <Checkbox
                          checked={isSelected}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => handleSelect(optionValue)}
                        />
                      )}
                    </button>
                  ) : (
                    child
                  )}
                </React.Fragment>
              )
            })}
          </Drawer.Items>
          {isClearable && (
            <div className="flex justify-end p-2">
              <Button size="xs" color="red" onClick={() => handleSelect(undefined)}>
                {t('clearValue.label')}
              </Button>
            </div>
          )}
        </Drawer>
      )}
    </>
  )
}

export default Select
