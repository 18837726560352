export const dataURLtoBlob = async (dataURL: string) => {
  const response = await fetch(dataURL)
  return await response.blob()
}
export const debounce = <Params extends unknown[], Result = unknown>(
  func: (...args: Params) => Promise<Result> | void,
  timeout = 300,
): ((...args: Params) => Promise<Result>) => {
  let timer: NodeJS.Timeout
  return (...args: Params) =>
    new Promise((resolve) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const result = func(...args)
        if (result) {
          resolve(result)
        }
      }, timeout)
    })
}
