import { Option } from '@/components/Select'
import { StatusCategory } from '@/projects/models/IProject'

export const statusCategoryOptions: Option<StatusCategory>[] = [
  {
    label: 'To-Do',
    value: StatusCategory.Todo,
    color: '#9D61F1',
  },
  {
    label: 'In Progress',
    value: StatusCategory.InProgress,
    color: '#FF805C',
  },
  {
    label: 'Done',
    value: StatusCategory.Done,
    color: '#52BE65',
  },
]
