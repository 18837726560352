import { NavLink } from 'react-router-dom'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

export interface TabItem {
  label: string
  to: string
}
export interface TabsProps {
  items: TabItem[]
}

const Tabs: React.FC<TabsProps> = ({ items }) => {
  return (
    <div className="border-b border-gray-200 px-3 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
      <ul className="-mb-px flex overflow-x-auto">
        {items.map((item) => (
          <li className="me-2 first-of-type:ms-2" key={item.to}>
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                twMerge(
                  cx('inline-block border-b-2 border-transparent px-4 py-2', {
                    'active border-b-2 border-wall-main-light text-wall-main-light dark:border-wall-main-dark dark:text-wall-main-dark':
                      isActive,
                  }),
                )
              }
              onClick={(e) => {
                e.currentTarget.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
