import type { CustomFlowbiteTheme } from 'flowbite-react'

const theme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: 'bg-wall-main-light dark:bg-wall-main-dark text-white',
      red: 'dark:bg-wall-secondary-bg-dark dark:text-wall-red-dark border dark:border-wall-gray-dark border-wall-gray-light text-wall-secondary bg-white',
    },
  },
  textInput: {
    field: {
      input: {
        colors: {
          gray: 'bg-white dark:bg-wall-secondary-bg-dark border-2 dark:border-wall-gray-dark border-wall-gray-light dark:focus:border-wall-main-dark focus:border-wall-secondary dark:focus:ring-wall-main-dark focus:ring-wall-secondary',
        },
        sizes: {
          xs: 'text-xs',
        },
      },
    },
  },
  textarea: {
    colors: {
      gray: 'bg-white dark:bg-wall-secondary-bg-dark border-2 dark:border-wall-gray-dark border-wall-gray-light dark:focus:border-wall-main-dark focus:border-wall-secondary dark:focus:ring-wall-main-dark focus:ring-wall-secondary',
    },
  },
  checkbox: {
    root: {
      base: 'h-4 w-4 rounded border-2 border-wall-gray-light bg-wall-main-bg-light focus:ring-2 dark:border-wall-gray-dark dark:bg-wall-main-bg-dark',
      color: {
        default:
          'text-wall-main-light dark:text-wall-main-dark focus:ring-wall-main-light dark:ring-offset-gray-800 dark:focus:wall-main-dark',
      },
    },
  },
  spinner: {
    color: {
      info: 'fill-wall-secondary',
    },
  },
}

export default theme
