import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import FileItem from './FileItem'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { DocumentPlusIcon } from '@heroicons/react/24/outline'

import { useGetFileList, useUploadFile } from '@/projects/queries'
import { getFilenameParts } from './utils'

export interface AttachmentsProps {
  isNewTicket?: boolean
  onChange?: (files: File[]) => void
}

const MAX_FILE_SIZE = 25 // MB

export const Attachments: React.FC<AttachmentsProps> = ({ isNewTicket = false, onChange }: AttachmentsProps) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.attachments' })
  const { ticketId, projectId, teamspaceId } = useParams<{ ticketId: string; projectId: string; teamspaceId: string }>()

  const { mutateAsync: upload } = useUploadFile(projectId, teamspaceId, ticketId)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const { data: files, refetch: refetchFiles } = useGetFileList(projectId, teamspaceId, ticketId, !isNewTicket)

  const [isBigFilesDialogOpened, setIsBigFilesDialogOpened] = useState(false)
  const [bigFilesNames, setBigFilesNames] = useState<Record<'name' | 'ext', string | null>[]>([])

  const inputRef = useRef<HTMLInputElement>(null)

  const checkFilesSize = (files: File[]): File[] => {
    const { ok, big } = Array.from(files).reduce<Record<'ok' | 'big', File[]>>(
      (acc, file) => {
        const sizeInMB = file.size / (1024 * 1024)
        if (sizeInMB >= MAX_FILE_SIZE) {
          return { ...acc, big: [...acc.big, file] }
        }
        return { ...acc, ok: [...acc.ok, file] }
      },
      { ok: [], big: [] },
    )

    if (big.length) {
      const names = big.map((file) => getFilenameParts(file.name))
      setBigFilesNames(names)
      setIsBigFilesDialogOpened(true)
    }

    return ok
  }

  const handleUpload = async () => {
    if (inputRef.current) {
      const files = inputRef.current.files as File[] | null
      if (files) {
        const filteredFiles = checkFilesSize(files)
        setUploadedFiles((prev) => {
          const newFiles = [...prev, ...filteredFiles]
          onChange?.(newFiles)
          return newFiles
        })

        if (!isNewTicket) {
          await Promise.allSettled(filteredFiles.map((file) => upload(file)))
          refetchFiles()
        }
      }
    }
  }

  const deleteJustUploaded = (name: string) => {
    setUploadedFiles((files) => {
      const newFiles = files.filter((file) => file.name !== name)
      onChange?.(newFiles)
      return newFiles
    })
  }

  useEffect(() => {
    if (files) {
      setUploadedFiles([])
    }
  }, [files])

  return (
    <div className="grid gap-1">
      <span className="text-xs">{t('title')}</span>
      <div className="grid gap-1">
        <div>
          {files?.map(({ filename, file, uuid }) => <FileItem key={uuid} id={uuid} name={filename} src={file} />)}
          {uploadedFiles
            .filter((file) => !files?.some((f) => f.filename === file.name))
            .map((file, i) => (
              <FileItem
                key={`uploading-${file.name}-${i}`}
                name={file.name}
                file={file}
                justUploaded
                isNewTicket={isNewTicket}
                onDelete={deleteJustUploaded}
              />
            ))}
        </div>
        <button
          className={clsx(
            'rounded-lg border bg-wall-dirtygray-light p-2 text-sm text-black dark:border-none dark:text-white',
            {
              'dark:bg-wall-main-bg-dark': isNewTicket,
              'dark:bg-wall-secondary-bg-dark': !isNewTicket,
            },
          )}
          type="button"
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.value = ''
              inputRef.current.click()
            }
          }}
        >
          <DocumentPlusIcon className="inline h-6 w-6" />
          <input type="file" className="hidden" onChange={handleUpload} ref={inputRef} multiple />
        </button>
        <ConfirmationDialog
          isOpen={isBigFilesDialogOpened}
          onClose={() => setIsBigFilesDialogOpened(false)}
          onConfirm={() => setIsBigFilesDialogOpened(false)}
          confirmationTitle={t('bigFilesAlert')}
          confirmLabel={t('understand')}
          confirmationText={
            <>
              <div>
                {bigFilesNames.map(({ name, ext }) => (
                  <div
                    key={name}
                    className="mb-1 flex justify-start text-xs text-gray-500 before:mr-2 before:content-['•'] dark:text-gray-400"
                  >
                    <span className="max-w-xs truncate">{name}</span>
                    <span className="">{`.${ext}`}</span>
                  </div>
                ))}
              </div>
              <div className="mt-3 font-semibold text-gray-500 dark:text-gray-400">{t('reduceTheSize')}</div>
            </>
          }
          withoutCancelBtn
        />
      </div>
    </div>
  )
}

export default Attachments
