import { useUtils } from '@telegram-apps/sdk-react'

import Image from '@/components/Image'
import Skeleton from '@/components/Skeleton'

import { sendEvent } from '@/utils/hooks/useAmplitude'

export interface PostProps {
  img: string
  id: number
  title: string
  postLink: string
}

export const Post: React.FC<PostProps> = ({ img, title, postLink, id }) => {
  const utils = useUtils()
  return (
    <button
      className="h-24 w-20 rounded"
      onClick={() => {
        utils.openTelegramLink(postLink)
        sendEvent('open-arcticle', { title, id })
      }}
    >
      <Skeleton isActive className="h-full w-full bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark">
        <Image src={img} alt={title} className="h-full w-full rounded" />
      </Skeleton>
    </button>
  )
}

export default Post
