import cx from 'clsx'
import { generatePath, useParams } from 'react-router-dom'
import { useAtom } from 'jotai'

import NavItem from '../NavItem'
import { HomeIcon, RectangleStackIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'

import routes from '@/utils/routes'
import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import TeamspaceSelector from '@/teamspace/components/TeamspaceSelector'
import { project as projectAtom } from '@/projects/store'

export interface NavigationProps {}

export const Navigation: React.FC<NavigationProps> = () => {
  const isIos = useIsIos()

  const { teamspaceId, projectId } = useParams<{ teamspaceId: string; projectId: string }>()
  const [selectedProject] = useAtom(projectAtom)

  return (
    <div
      className={cx(
        'w-full border-t border-gray-200 bg-wall-secondary-bg-light dark:border-gray-600 dark:bg-wall-secondary-bg-dark',
        isIos ? 'h-20' : 'h-12',
      )}
    >
      <div className="relative grid h-full grid-cols-4 font-medium">
        <TeamspaceSelector />
        <NavItem
          to={generatePath(routes.private.home, {
            teamspaceId: teamspaceId || '',
            projectId: projectId || selectedProject || '',
          })}
          Icon={HomeIcon}
        />
        <NavItem
          to={generatePath(routes.private.projects, { teamspaceId: teamspaceId || '' })}
          Icon={RectangleStackIcon}
        />
        <NavItem to={generatePath(routes.private.settings, { teamspaceId: teamspaceId || '' })} Icon={Cog6ToothIcon} />
      </div>
    </div>
  )
}

export default Navigation
