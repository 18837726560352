import { useEffect, useMemo } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { useBackButton as useTelegramBackButton } from '@telegram-apps/sdk-react'
import routes from '../routes'

const useBackButton = (teamspaceId?: string, projectId?: string) => {
  const backButton = useTelegramBackButton()

  const navigate = useNavigate()
  const { state } = useLocation()

  const backRoute = useMemo(() => {
    if (state?.from) {
      return state.from
    } else {
      return generatePath(routes.private.board, { teamspaceId, projectId })
    }
  }, [projectId, teamspaceId])

  useEffect(() => {
    if (backButton) {
      backButton.show()
      return backButton.on('click', () => {
        navigate(backRoute)
        backButton.hide()
      })
    }
  }, [backButton, backRoute])

  return { backRoute }
}

export default useBackButton
