import { useDndContext } from '@dnd-kit/core'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useState } from 'react'

import Column from '../Column'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'

import { IColumn } from '@/projects/models/IProject'
import { useScrollRestorationRef } from '@/utils/hooks/useScrollRestorationRef'
import { useIsIos, useIsAndroid } from '@/utils/hooks/useIsMobileDevice'

export interface ColumnsProps {
  columns: IColumn[]
  boardKey: string
  id: string
  isLoading?: boolean
}
const minSwipeDistance = 50

export const Columns: React.FC<ColumnsProps> = ({ columns, boardKey, id, isLoading }) => {
  const { active } = useDndContext()
  const isIos = useIsIos()
  const isAndroid = useIsAndroid()
  const scrollRestorationRef = useScrollRestorationRef(`#board-${id}`)

  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)
  const [isCreating, setIsCreating] = useState<string | null>(null)

  const handleRightScroll = () => {
    if (scrollRestorationRef.current) {
      const offset = (scrollRestorationRef.current?.children?.[1]?.clientWidth || 0) + 12
      const scrollLeft = scrollRestorationRef.current.scrollLeft + offset

      if (scrollRestorationRef.current.scrollWidth - scrollLeft - offset < 12) {
        return
      }

      if (isAndroid) {
        scrollRestorationRef.current.scrollLeft = scrollLeft
      } else {
        scrollRestorationRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        })
      }
    }
  }

  const handleLeftScroll = () => {
    const offset = (scrollRestorationRef.current?.children?.[1]?.clientWidth || 0) + 12
    const scrollLeft = (scrollRestorationRef?.current?.scrollLeft || 0) - offset

    if (isAndroid) {
      scrollRestorationRef.current && (scrollRestorationRef.current.scrollLeft = scrollLeft)
    } else {
      scrollRestorationRef.current?.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      })
    }
  }

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onMouseMoveStart = (e: React.MouseEvent<HTMLDivElement>) => {
    setTouchEnd(null)
    setTouchStart(e.clientX)
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX)

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => setTouchEnd(e.clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      handleRightScroll()
    }
    if (isRightSwipe) {
      handleLeftScroll()
    }
  }

  return (
    <div
      className={twMerge(
        cx('relative flex h-full gap-3 overflow-x-hidden transition-[flex-direction]', {
          'flex-col gap-0 px-3': !!active,
          'touch-none': isCreating,
        }),
      )}
      ref={scrollRestorationRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseMoveStart}
      onMouseMoveCapture={onMouseMove}
      onMouseUp={onTouchEnd}
    >
      {!active && <div className="" />}
      {columns.map((column) => (
        <Column
          key={column.id}
          boardKey={boardKey}
          id={column.id}
          title={column.title}
          cards={column.cards}
          color={column.color}
          isCreating={isCreating}
          setIsCreating={setIsCreating}
          showSwipeTip={!column.cards.length && !isLoading}
        />
      ))}
      {!active && <div className="min-w-[1px]" />}
      <div className={cx('fixed left-3 flex gap-2', isIos ? 'bottom-[90px]' : 'bottom-14')}>
        <button
          className="rounded-full border bg-wall-secondary-bg-light px-2 py-1 dark:border-gray-600 dark:bg-wall-secondary-bg-dark"
          onClick={handleLeftScroll}
        >
          <ArrowLeftIcon className="h-5 w-5" />
        </button>
        <button
          className="rounded-full border bg-wall-secondary-bg-light px-2 py-1 dark:border-gray-600 dark:bg-wall-secondary-bg-dark"
          onClick={handleRightScroll}
        >
          <ArrowRightIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  )
}

export default Columns
