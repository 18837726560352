import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

import Chat from './components/Chat'
import Key from './components/Key'
import Name from './components/Name'
import Language from './components/Language'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import Features from './components/Features'
import { TrashIcon } from '@heroicons/react/24/outline'

import { useDeleteProject, useGetAllProjects, useGetProject } from '@/projects/queries'
import { useToggleOpen } from '@/utils/hooks/useToggle'
import { useGetMe } from '@/auth/queries'
import routes from '@/utils/routes'
import { project as projectAtom } from '@/projects/store'
import { sendEvent } from '@/utils/hooks/useAmplitude'
import Notifications from './components/Notifications'

export interface SettingsProps {}

export const Settings: React.FC<SettingsProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings' })
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const navigate = useNavigate()

  const { data: project } = useGetProject(projectId, teamspaceId)
  const { data: projects } = useGetAllProjects()
  const { data: me } = useGetMe()
  const { mutateAsync: deleteProject } = useDeleteProject(projectId, teamspaceId)

  const [, setSelectedProject] = useAtom(projectAtom)

  const { toggleOpen, isOpen } = useToggleOpen()

  useEffect(() => {
    sendEvent('open-project-settings')
  }, [])

  return (
    <div className="flex h-full flex-col gap-2 overflow-hidden px-3">
      <Name />
      <Key />
      {me && project && me.uuid === project?.owner_id && (
        <>
          <Chat />
          <Language />
        </>
      )}
      <Notifications />
      <Features />
      {me.uuid === project?.owner_id && (
        <>
          <div className="flex flex-grow items-end justify-end pb-3">
            <button onClick={() => toggleOpen()}>
              <TrashIcon className="h-5 w-5 text-red-500" />
            </button>
          </div>
          <ConfirmationDialog
            confirmationTitle={t('deleteConfirmationTitle')}
            onClose={toggleOpen}
            onConfirm={async () => {
              await deleteProject(undefined)
              const navigateToProject = projects.find((project) => project.uuid !== projectId)
              if (navigateToProject) {
                setSelectedProject(navigateToProject.uuid)
              }
              sendEvent('delete-project')
              navigate(
                navigateToProject
                  ? generatePath(routes.private.home, {
                      teamspaceId: navigateToProject?.teamspace_id || '',
                      projectId: navigateToProject?.uuid || '',
                    })
                  : '/',
              )
            }}
            isOpen={isOpen}
          />
        </>
      )}
    </div>
  )
}

export default Settings
