import { useCallback, useEffect, useId, useState } from 'react'
import { Button, Drawer } from 'flowbite-react'
import Calendar from 'react-calendar'
import cx from 'clsx'

import { useIsIos } from '@/utils/hooks/useIsMobileDevice'

import './index.css'

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export interface DatepickerProps<TValue> {
  placeholder?: string
  buttonClassName?: string
  onChange: (value: TValue | null) => void
  value?: TValue
  isClearable?: boolean
  label?: string
}

export function Datepicker<TValue extends Value = ValuePiece>({
  buttonClassName,
  placeholder,
  onChange: onChangeValue,
  value: valueProps,
  isClearable = false,
  label,
}: DatepickerProps<TValue>) {
  const isIos = useIsIos()
  const id = useId()

  const [isOpen, setIsOpen] = useState(false)
  const [value, onChange] = useState<TValue | null | undefined>(valueProps || undefined)

  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setIsOpen(true)
  }, [])

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const drawer = document.getElementById(id)
    if (drawer && isOpen) {
      drawer.nextSibling?.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        handleClose()
      })
    }
  }, [isOpen])

  useEffect(() => {
    onChange(value as TValue)
  }, [valueProps])

  return (
    <>
      <div className="flex flex-col gap-1">
        {label && <span className="text-xs">{label}</span>}
        <Button
          onClick={onClick}
          size="xs"
          color="gray"
          type="button"
          className={cx(
            'text-black dark:bg-wall-secondary-bg-dark dark:text-neutral-100 dark:hover:bg-wall-main-bg-dark',
            buttonClassName,
          )}
        >
          <>
            {value
              ? Array.isArray(value)
                ? `${value[0]?.toLocaleDateString()} - ${value[1]?.toLocaleDateString()}`
                : value?.toLocaleDateString()
              : placeholder}
          </>
        </Button>
      </div>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        position="bottom"
        className={cx('calendar-wrapper rounded-t-md', { 'pb-10': isIos })}
        id={id}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Calendar
          onChange={(value) => {
            onChange(value as TValue)
            onChangeValue?.(value as TValue)
            handleClose()
          }}
          value={value}
          className="mx-auto min-h-[318px]"
        />
        {isClearable && (
          <div className="flex justify-end px-2">
            <Button
              color="gray"
              size="xs"
              onClick={() => {
                onChange(null)
                onChangeValue?.(null)
                handleClose()
              }}
              className="mt-3"
            >
              Clear
            </Button>
          </div>
        )}
      </Drawer>
    </>
  )
}

export default Datepicker
