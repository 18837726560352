import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useUtils } from '@telegram-apps/sdk-react'

import LanguagePicker from '@/components/Language'
import Logo from '@/assets/logo.svg?react'

import { ChatBubbleOvalLeftEllipsisIcon, GlobeAltIcon, LanguageIcon } from '@heroicons/react/24/outline'
import { useGetMe, usePatchMe } from '@/auth/queries'

import { Language } from '@/i18n'

export interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'settings' })
  const { data: user } = useGetMe()
  const { mutateAsync: patchMe } = usePatchMe()
  const utils = useUtils()

  const nameAbbr = user?.first_name?.slice(0, 1) || '' + user?.last_name?.slice(0, 1) || ''

  return (
    <div className="flex h-full flex-col gap-3 p-3">
      <div className="flex w-full gap-2 rounded bg-wall-secondary-bg-light p-2 dark:bg-wall-secondary-bg-dark">
        <div className="h-14 w-14 rounded bg-wall-main-light p-3 text-center text-xl uppercase text-white dark:bg-wall-main-dark">
          {nameAbbr}
        </div>
        <div className="flex flex-col py-2">
          <p className="text-lg leading-5">{[user?.first_name, user?.last_name].filter(Boolean).join(' ')}</p>
          <p className="text-sm text-wall-gray-neutral">@{user?.username}</p>
        </div>
      </div>
      <div className="flex w-full flex-col gap-3 rounded bg-wall-secondary-bg-light p-2 dark:bg-wall-secondary-bg-dark">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <LanguageIcon className="h-5 w-5" />
            {t('languageLabel')}
          </div>
          <LanguagePicker
            code={user.language_code || Language.EN}
            onChange={(code) => {
              i18n.changeLanguage(code)
              patchMe({ language_code: code, first_name: user?.first_name, last_name: user?.last_name })
            }}
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <GlobeAltIcon className="h-5 w-5" />
            {t('timeZoneLabel')}
          </div>
          <span className="text-wall-gray-neutral">{dayjs().format('Z')}</span>
        </div>
      </div>
      <div className="flex w-full flex-col gap-3 rounded bg-wall-secondary-bg-light p-2 dark:bg-wall-secondary-bg-dark">
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={() => {
            utils.openLink(t('landingLink'), { tryInstantView: true })
          }}
        >
          <Logo className="h-5 w-5 stroke-black dark:stroke-white" />
          {t('aboutLabel')}
        </div>
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={() => utils.openTelegramLink('https://t.me/the_wall_supportbot')}
        >
          <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" />
          {t('supportLabel')}
        </div>
      </div>
    </div>
  )
}

export default Home
